export const initialState = {
  displayKanji: true,
  displayStrokeOrder: false,
  displayHiragana: true,
  displayRomaji: true,
  displayEnglish: false,
  displayCharacterNum: false,
  sutra: "heartsutra",
  hideMenu: false
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    // Do something here based on the different types of actions
    case "update":
      const newState = { ...state };
      newState[action.currentTargetName] = action.currentTargetValue;
      return newState;
    default:
      // If this reducer doesn't recognize the action type, or doesn't
      // care about this specific action, return the existing state unchanged

      return state;
  }
}
