import { useSelector, useDispatch } from "react-redux";
import React, { useState } from "react";

const cx = require("classnames");

function LogoBar(props) {
  const sutra = useSelector((state) => state.sutra);
  const displayKanji = useSelector((state) => state.displayKanji);
  const displayStrokeOrder = useSelector((state) => state.displayStrokeOrder);
  const displayHiragana = useSelector((state) => state.displayHiragana);
  const displayRomaji = useSelector((state) => state.displayRomaji);
  const displayEnglish = useSelector((state) => state.displayEnglish);
  const displayCharacterNum = useSelector((state) => state.displayCharacterNum);

  const dispatch = useDispatch();

  const changeValue = (evnt) => {
    dispatch({
      type: "update",
      currentTargetName: evnt.currentTarget.name,
      currentTargetValue:
        evnt.currentTarget.type === "checkbox"
          ? evnt.currentTarget.checked
          : evnt.currentTarget.value,
    });
    if (!displayKanji) {
      dispatch({
        type: "update",
        currentTargetName: displayStrokeOrder,
        currentTargetValue: false,
      });
    }
  };
  const ClickHideMenu = (evnt) => {
    dispatch({
      type: "update",
      currentTargetName: "hideMenu",
      currentTargetValue: true,
    });
  };
  let menutext = "Menu";
  const hideMenu = useSelector((state) => state.hideMenu);

  return (
    <div
      className={cx(
        "row",
        "logo-bar",
        props.expanded ? "expanded" : "",
        hideMenu ? "hidden" : ""
      )}
    >
      <div className="logo">
        <a
          href="#"
          className={cx(
            "col-xs-3",
            "logo-button",
            props.expanded ? "expanded" : ""
          )}
          onClick={props.onClick}
        >
          {menutext}
        </a>
      </div>
      <div className="options">
        <div className={cx(props.expanded ? "" : "hidden")}>
          <div style={{ marginTop: "1.5em", height: "1.5em", width: "100%" }}>
            Select a sutra
          </div>
          <select name="sutra" defaultValue="heartsutra" onChange={changeValue}>
            <option>Select One</option>
            <optgroup label="Maka Hannya Haramitta Shin Gyo">
              <option selected={sutra === "heartsutra"} value="heartsutra">
                Heart of Great Perfect Wisdom Sutra
              </option>
            </optgroup>
            <optgroup label="Sandokai">
              <option selected={sutra === "sandokai"} disabled value="yes">
                Harmony of Difference and Equality
              </option>
            </optgroup>
            <optgroup label="Hokyo Zammai">
              <option selected={sutra === "hokyozammai"} disabled value="yes">
                Song of the Jewel Mirror Samadhi
              </option>
            </optgroup>
          </select>

          <div style={{ marginTop: "1.5em", height: "1.5em", width: "100%" }}>
            Select what you would like to display
          </div>
          <label>
            <input
              type="checkbox"
              name="displayKanji"
              defaultChecked={displayKanji}
              onChange={changeValue}
            />
            Kanji
          </label>
          <label>
            <input
              type="checkbox"
              name="displayStrokeOrder"
              defaultChecked={displayStrokeOrder}
              disabled={!displayKanji}
              style={{ marginLeft: "1.5em" }}
              onChange={changeValue}
            />
            Stroke Order
          </label>
          <label>
            <input
              type="checkbox"
              name="displayHiragana"
              defaultChecked={displayHiragana}
              onChange={changeValue}
            />
            Hiragana
          </label>
          <label>
            <input
              type="checkbox"
              name="displayRomaji"
              defaultChecked={displayRomaji}
              onChange={changeValue}
            />
            Romaji
          </label>
          <label>
            <input
              type="checkbox"
              name="displayEnglish"
              defaultChecked={displayEnglish}
              onChange={changeValue}
            />
            English
          </label>
          <label>
            <input
              type="checkbox"
              name="displayCharacterNum"
              defaultChecked={displayCharacterNum}
              onChange={changeValue}
            />
            Character #
          </label>
        </div>
      </div>
      <div className="close-menu-button">
        <a onClick={ClickHideMenu}>X</a>
      </div>
    </div>
  );
}

export function Header(props) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpandMenu = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };
  return (
    <header>
      <LogoBar
        {...{
          expanded,
          onClick: toggleExpandMenu,
        }}
      />
    </header>
  );
}
