import { configureStore } from '@reduxjs/toolkit'
const {initialState, appReducer} = require('./reducer');


const store = configureStore({
    reducer: appReducer,
    devTools: process.env.NODE_ENV !== 'production',
    initialState
})

export default store

