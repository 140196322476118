import "./stylesheets/application.scss";
import { useSelector } from 'react-redux'
const React = require('react');

const cx = require("classnames");
const {Sutra} = require("./components/sutra");
const {Header} = require("./components/header");

export default function App() {
  const stateSutra = useSelector((state) => state.sutra);
  const displayKanji = useSelector((state) => state.displayKanji);
  const displayHiragana = useSelector((state) => state.displayHiragana);
  const displayRomaji = useSelector((state) => state.displayRomaji);
  const displayEnglish = useSelector((state) => state.displayEnglish);
  const displayCharacterNum = useSelector((state) => state.displayCharacterNum);
  const isOnlyKanji = displayKanji &&
    !(
      displayHiragana &&
      displayRomaji &&
      displayEnglish &&
      displayCharacterNum
    )

  const whichSutra = stateSutra ? stateSutra : 'heartsutra'
  const kanjiblobData = require("./sutras/" + whichSutra + ".js");

  const content = (
    <div className={cx("header_spacer","row", !isOnlyKanji ? "showBorder" : "")}>
        <Sutra  {...kanjiblobData}/>
    </div>
  );
  return (<div className="App">
    <Header />
    <div style={{height: '40px', width: '100%' }}></div>
    {content}
  </div>);
}

