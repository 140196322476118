import { useSelector } from "react-redux";
const React = require("react");
const { Kanjiblob } = require("./kanjiblob");
const cx = require("classnames");

export function Sutra(kanjiblobData) {
  const displayKanji = useSelector((state) => state.displayKanji);
  return (
    <div
      className={cx(displayKanji ? "sutra-container-kanji" : "sutra-container")}
    >
      <div className={cx(displayKanji ? "title-kanji" : "title")}>
        {kanjiblobData.title.map(function (currKanjiBlob) {
          currKanjiBlob.title = "true";
          return (
            <Kanjiblob key={currKanjiBlob.key} kanjiBlob={currKanjiBlob} />
          );
        }, this)}
      </div>

      <div className={cx(displayKanji ? "sutra-body-kanji" : "sutra-body")}>
        {kanjiblobData.sutra.map(function (currKanjiBlob) {
          return (
            <Kanjiblob key={currKanjiBlob.key} kanjiBlob={currKanjiBlob} />
          );
        }, this)}
      </div>
    </div>
  );
}
