const heartsutra = {
    sutra: [
        {key: '001', kanji: '観', hiragana: 'かん', romaji: 'kan', english: 'kan transliteration for "Kanjizai" aka "Kanzeon"'},
        {key: '002', kanji: '自', hiragana: 'じ', romaji: 'ji', english: 'ji transliteration for "Kanjizai" aka "Kanzeon"'},
        {key: '003', kanji: '在', hiragana: 'ざい', romaji: 'zai', english: 'zai transliteration for "Kanjizai" aka "Kanzeon"'},
        {key: '004', kanji: '菩', hiragana: 'ぼ', romaji: 'bo', english: 'bo transliteration for bodhisattvha'},
        {key: '005', kanji: '薩', hiragana: 'さつ', romaji: 'satsu', english: 'satsu transliteration for bodhisattvha'},
        {key: '006', kanji: '行', hiragana: 'ぎょう', romaji: 'gyō', english: 'thing, to go'},
        {key: '008', kanji: '深', hiragana: 'じん', romaji: 'jin', english: 'profound'},
        {key: '009', kanji: '般', hiragana: 'はん', romaji: 'han', english: 'Han in transliteration of Prajñā'},
        {key: '010', kanji: '若', hiragana: 'にゃ', romaji: 'nya', english: 'Nya in transliteration of Prajñā'},
        {key: '011', kanji: '波', hiragana: 'は', romaji: 'ha', english: 'Ha in transliteration of Pāramitā'},
        {key: '012', kanji: '羅', hiragana: 'ら', romaji: 'ra', english: 'Ra in transliteration of Pāramitā'},
        {key: '013', kanji: '蜜', hiragana: 'みっ', romaji: 'mit', english: 'Mit in transliteration of Pāramitā'},
        {key: '014', kanji: '多', hiragana: 'た', romaji: 'ta', english: 'Ta in transliteration of Pāramitā'},
        {key: '015', kanji: '時', hiragana: 'じ', romaji: 'ji', english: 'time?'},
        {key: '016', kanji: '照', hiragana: 'しょう', romaji: 'shō', english: 'illumination'},
        {key: '017', kanji: '見', hiragana: 'けん', romaji: 'ken', english: 'see'},
        {key: '018', kanji: '五', hiragana: 'ご', romaji: 'go', english: 'five'},
        {key: '019', kanji: '蘊', hiragana: 'うん', romaji: 'on', english: 'skhanda'},
        {key: '020', kanji: '皆', hiragana: 'かい', romaji: 'kai', english: 'everyone?'},
        {key: '021', kanji: '空', hiragana: 'くう', romaji: 'kū', english: 'emptiness'},
        {key: '022', kanji: '度', hiragana: 'ど', romaji: 'do', english: 'degree?'},
        {key: '023', kanji: '一', hiragana: 'いっ', romaji: 'is', english: 'one'},
        {key: '024', kanji: '切', hiragana: 'さい', romaji: 'sai', english: 'cut'},
        {key: '025', kanji: '苦', hiragana: 'く', romaji: 'ku', english: 'dukkha'},
        {key: '026', kanji: '厄', hiragana: 'やく', romaji: 'yaku', english: 'evil'},
        {key: '027', kanji: '舎', hiragana: 'さ', romaji: 'sha', english: 'suffix (house)?'},
        {key: '028', kanji: '利', hiragana: 'り', romaji: 'ri', english: 'benefit'},
        {key: '029', kanji: '子', hiragana: 'し', romaji: 'shi', english: 'dimunitive object?'},
        {key: '030', kanji: '色', hiragana: 'しき', romaji: 'shiki', english: 'color? kin?'},
        {key: '031', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '032', kanji: '異', hiragana: 'い', romaji: 'i', english: 'different? other? odd?'},
        {key: '033', kanji: '空', hiragana: 'くう', romaji: 'kū', english: 'emptiness'},
        {key: '034', kanji: '空', hiragana: 'くう', romaji: 'kū', english: 'emptiness'},
        {key: '035', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '036', kanji: '異', hiragana: 'い', romaji: 'i', english: 'different? other? odd?'},
        {key: '037', kanji: '色', hiragana: 'しき', romaji: 'shiki', english: 'color? kin?'},
        {key: '038', kanji: '色', hiragana: 'しき', romaji: 'shiki', english: 'color? kin?'},
        {key: '039', kanji: '即', hiragana: 'そく', romaji: 'soku', english: 'immediately'},
        {key: '040', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '041', kanji: '空', hiragana: 'くう', romaji: 'kū', english: 'emptiness'},
        {key: '042', kanji: '空', hiragana: 'くう', romaji: 'kū', english: 'emptiness'},
        {key: '043', kanji: '即', hiragana: 'そく', romaji: 'soku', english: 'immediately'},
        {key: '044', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '045', kanji: '色', hiragana: 'しき', romaji: 'shiki', english: 'color? kin?'},
        {key: '046', kanji: '受', hiragana: 'じゅ', romaji: 'ju', english: 'to receive?'},
        {key: '047', kanji: '想', hiragana: 'そう', romaji: 'sō', english: 'thought, idea, concept'},
        {key: '048', kanji: '行', hiragana: 'ぎょう', romaji: 'gyō', english: 'thing?'},
        {key: '049', kanji: '職', hiragana: 'しき', romaji: 'shiki', english: 'employment?'},
        {key: '050', kanji: '亦', hiragana: 'やく', romaji: 'yaku', english: 'used in names?'},
        {key: '051', kanji: '復', hiragana: 'ぶ', romaji: 'bu', english: 'restore, revert, resume'},
        {key: '052', kanji: '如', hiragana: 'にょ', romaji: 'nyo', english: 'to seem'},
        {key: '053', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '054', kanji: '舎', hiragana: 'さ', romaji: 'sha', english: 'suffix (house)?'},
        {key: '055', kanji: '利', hiragana: 'り', romaji: 'ri', english: 'benefit'},
        {key: '056', kanji: '子', hiragana: 'し', romaji: 'shi', english: 'dimunitive object?'},
        {key: '057', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '058', kanji: '諸', hiragana: 'しょ', romaji: 'sho', english: 'various, many, together'},
        {key: '059', kanji: '法', hiragana: 'ほう', romaji: 'ho', english: 'dharma'},
        {key: '060', kanji: '空', hiragana: 'くう', romaji: 'kū', english: 'emptiness'},
        {key: '061', kanji: '柤', hiragana: 'そ', romaji: 'so', english: '?'},
        {key: '062', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '063', kanji: '生', hiragana: 'しょう', romaji: 'shō', english: 'live, raw, natural'},
        {key: '064', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '065', kanji: '滅', hiragana: 'めつ', romaji: 'metsu', english: 'die, perish, dissappear'},
        {key: '066', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '067', kanji: '垢', hiragana: 'く', romaji: 'ku', english: 'grime, dirt'},
        {key: '068', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '069', kanji: '浄', hiragana: 'じょう', romaji: 'jō', english: 'pure, unspoiled'},
        {key: '070', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '071', kanji: '増', hiragana: 'ぞう', romaji: 'zō', english: 'increase'},
        {key: '072', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '073', kanji: '減', hiragana: 'げん', romaji: 'gen', english: 'reduction'},
        {key: '074', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '075', kanji: '故', hiragana: 'こ', romaji: 'ko', english: 'late, deceased'},
        {key: '076', kanji: '空', hiragana: 'くう', romaji: 'kū', english: 'emptiness'},
        {key: '077', kanji: '中', hiragana: 'ちゅ', romaji: 'chu', english: 'inside'},
        {key: '078', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '079', kanji: '色', hiragana: 'しき', romaji: 'shiki', english: 'color? kin?'},
        {key: '080', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '081', kanji: '受', hiragana: 'じゅ', romaji: 'ju', english: 'to receive?'},
        {key: '082', kanji: '想', hiragana: 'そう', romaji: 'sō', english: 'thought, idea, concept'},
        {key: '083', kanji: '行', hiragana: 'ぎょう', romaji: 'gyō', english: 'thing?'},
        {key: '084', kanji: '職', hiragana: 'しき', romaji: 'shiki', english: 'employment?'},
        {key: '085', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '086', kanji: '眠', hiragana: 'げん', romaji: 'gen', english: '?'},
        {key: '087', kanji: '耳', hiragana: 'に', romaji: 'ni', english: 'ear'},
        {key: '088', kanji: '鼻', hiragana: 'び', romaji: 'bi', english: 'nose'},
        {key: '089', kanji: '舌', hiragana: 'ぜつ', romaji: 'ze', english: 'tongue'},
        {key: '090', kanji: '身', hiragana: 'しん', romaji: 'shin', english: 'body'},
        {key: '091', kanji: '意', hiragana: 'に', romaji: 'ni', english: 'idea, mind, heart, desire'},
        {key: '092', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '093', kanji: '色', hiragana: 'しき', romaji: 'shiki', english: 'color? kin?'},
        {key: '094', kanji: '声', hiragana: 'しょう', romaji: 'shō', english: 'voice'},
        {key: '095', kanji: '香', hiragana: 'こう', romaji: 'kō', english: 'fragrance'},
        {key: '096', kanji: '味', hiragana: 'み', romaji: 'mi', english: 'taste'},
        {key: '097', kanji: '触', hiragana: 'そく', romaji: 'soku', english: 'touch'},
        {key: '098', kanji: '法', hiragana: 'ほ', romaji: 'ho', english: 'dharma'},
        {key: '099', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '100', kanji: '眠', hiragana: 'げん', romaji: 'gen', english: '?'},
        {key: '101', kanji: '界', hiragana: 'かい', romaji: 'kai', english: 'world'},
        {key: '102', kanji: '乃', hiragana: 'ない', romaji: 'nai', english: 'then, really, as it turned out'},
        {key: '103', kanji: '至', hiragana: 'し', romaji: 'shi', english: 'male given name?'},
        {key: '104', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '105', kanji: '意', hiragana: 'い', romaji: 'i', english: 'thoughts, meaning'},
        {key: '106', kanji: '職', hiragana: 'しき', romaji: 'shiki', english: 'employment?'},
        {key: '107', kanji: '界', hiragana: 'かい', romaji: 'kai', english: 'world'},
        {key: '108', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '109', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '110', kanji: '明', hiragana: 'みょう', romaji: 'myō', english: 'clear, bright'},
        {key: '111', kanji: '亦', hiragana: 'やく', romaji: 'yaku', english: 'used in names?'},
        {key: '112', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '113', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '114', kanji: '明', hiragana: 'みょう', romaji: 'myō', english: 'clear, bright'},
        {key: '115', kanji: '尽', hiragana: 'じん', romaji: 'jin', english: 'exhaust? deplete?'},
        {key: '116', kanji: '乃', hiragana: 'ない', romaji: 'nai', english: 'then, really, as it turned out'},
        {key: '117', kanji: '至', hiragana: 'し', romaji: 'shi', english: 'male given name?'},
        {key: '118', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '119', kanji: '老', hiragana: 'ろう', romaji: 'rō', english: 'old age'},
        {key: '120', kanji: '死', hiragana: 'し', romaji: 'shi', english: 'death'},
        {key: '121', kanji: '亦', hiragana: 'やく', romaji: 'yaku', english: 'used in names?'},
        {key: '122', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '123', kanji: '老', hiragana: 'ろう', romaji: 'rō', english: 'old age'},
        {key: '124', kanji: '死', hiragana: 'し', romaji: 'shi', english: 'death'},
        {key: '125', kanji: '尽', hiragana: 'じん', romaji: 'jin', english: 'exhaust? deplete?'},
        {key: '126', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '127', kanji: '苦', hiragana: 'く', romaji: 'ku', english: 'dukkha'},
        {key: '128', kanji: '集', hiragana: 'す', romaji: 'shu', english: 'kind, type'},
        {key: '129', kanji: '滅', hiragana: 'めつ', romaji: 'metsu', english: 'die, perish, dissappear'},
        {key: '130', kanji: '道', hiragana: 'ど', romaji: 'do', english: 'path, way'},
        {key: '131', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '132', kanji: '智', hiragana: 'ち', romaji: 'chi', english: 'wisdom?'},
        {key: '133', kanji: '亦', hiragana: 'やく', romaji: 'yaku', english: 'used in names?'},
        {key: '134', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '135', kanji: '得', hiragana: 'とく', romaji: 'toku', english: 'benefit'},
        {key: '136', kanji: '以', hiragana: 'い', romaji: 'i', english: 'compared to, by means of'},
        {key: '137', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '138', kanji: '所', hiragana: 'しょ', romaji: 'sho', english: 'place'},
        {key: '139', kanji: '得', hiragana: 'とく', romaji: 'toku', english: 'benefit'},
        {key: '140', kanji: '故', hiragana: 'こ', romaji: 'ko', english: 'late, deceased'},
        {key: '141', kanji: '菩', hiragana: 'ぼ', romaji: 'bo', english: 'bo transliteration for bodhisattvha?'},
        {key: '142', kanji: '提', hiragana: 'だい', romaji: 'dai', english: 'dai  transliteration for bodhisattvha?'},
        {key: '143', kanji: '薩', hiragana: 'さつ', romaji: 'satsu', english: 'sat transliteration for bodhisattvha?'},
        {key: '144', kanji: '埵', hiragana: 'た', romaji: 'ta', english: 'ta transliteration for bodhisattvha?'},
        {key: '145', kanji: '依', hiragana: 'え', romaji: 'e', english: 'rely on?'},
        {key: '146', kanji: '般', hiragana: 'はん', romaji: 'han', english: 'Han in transliteration of Prajñā'},
        {key: '147', kanji: '若', hiragana: 'にゃ', romaji: 'nya', english: 'Nya in transliteration of Prajñā'},
        {key: '148', kanji: '波', hiragana: 'は', romaji: 'ha', english: 'Ha in transliteration of Pāramitā'},
        {key: '149', kanji: '羅', hiragana: 'ら', romaji: 'ra', english: 'Ra in transliteration of Pāramitā'},
        {key: '150', kanji: '蜜', hiragana: 'みっ', romaji: 'mit', english: 'Mit in transliteration of Pāramitā'},
        {key: '151', kanji: '多', hiragana: 'た', romaji: 'ta', english: 'Ta in transliteration of Pāramitā'},
        {key: '152', kanji: '故', hiragana: 'こ', romaji: 'ko', english: 'late, deceased'},
        {key: '153', kanji: '心', hiragana: 'しん', romaji: 'shin', english: 'heart'},
        {key: '154', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '155', kanji: '罣', hiragana: 'け', romaji: 'ke', english: 'obstruct'},
        {key: '156', kanji: '礙', hiragana: 'げ', romaji: 'ge', english: 'hinderance'},
        {key: '157', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '158', kanji: '罣', hiragana: 'け', romaji: 'ke', english: 'obstruct'},
        {key: '159', kanji: '礙', hiragana: 'げ', romaji: 'ge', english: 'hinderance'},
        {key: '160', kanji: '故', hiragana: 'こ', romaji: 'ko', english: 'late, deceased'},
        {key: '161', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '162', kanji: '有', hiragana: 'う', romaji: 'u', english: 'exist, possess, occur'},
        {key: '163', kanji: '恐', hiragana: 'く', romaji: 'ku', english: 'fear'},
        {key: '164', kanji: '怖', hiragana: 'ふ', romaji: 'fu', english: 'frighten?'},
        {key: '165', kanji: '遠', hiragana: 'おん', romaji: 'on', english: 'distant, far, (literary) to distance oneself from'},
        {key: '166', kanji: '離', hiragana: 'り', romaji: 'ri', english: 'to leave, to go away, separate'},
        {key: '167', kanji: '一', hiragana: 'いっ', romaji: 'is', english: 'one'},
        {key: '168', kanji: '切', hiragana: 'さい', romaji: 'sai', english: 'cut'},
        {key: '169', kanji: '顚', hiragana: 'てん', romaji: 'ten', english: ''},
        {key: '170', kanji: '倒', hiragana: 'どう', romaji: 'dō', english: 'chinese: inverted, japanese : debt'},
        {key: '171', kanji: '夢', hiragana: 'む', romaji: 'mu', english: 'dream, visionary'},
        {key: '172', kanji: '想', hiragana: 'そう', romaji: 'sō', english: 'thought, idea, concept'},
        {key: '173', kanji: '究', hiragana: 'く', romaji: 'ku', english: 'examine, investigate'},
        {key: '174', kanji: '竟', hiragana: 'きょう', romaji: 'kyo', english: 'finally, after all, at last'},
        {key: '175', kanji: '涅', hiragana: 'ね', romaji: 'ne', english: 'part of abbreviation for "nirvana"'},
        {key: '176', kanji: '槃', hiragana: 'はん', romaji: 'han', english: 'part of abbreviation for "nirvana"'},
        {key: '177', kanji: '三', hiragana: 'さん', romaji: 'san', english: 'three'},
        {key: '178', kanji: '世', hiragana: 'せ', romaji: 'se', english: 'generation, age'},
        {key: '179', kanji: '諸', hiragana: 'しょ', romaji: 'sho', english: 'various, many, together'},
        {key: '180', kanji: '仏', hiragana: 'ぶつ', romaji: 'butsu', english: 'Buddha'},
        {key: '181', kanji: '依', hiragana: 'え', romaji: 'e', english: 'rely on?'},
        {key: '182', kanji: '般', hiragana: 'はん', romaji: 'han', english: 'Han in transliteration of Prajñā'},
        {key: '183', kanji: '若', hiragana: 'にゃ', romaji: 'nya', english: 'Nya in transliteration of Prajñā'},
        {key: '184', kanji: '波', hiragana: 'は', romaji: 'ha', english: 'Ha in transliteration of Pāramitā'},
        {key: '185', kanji: '羅', hiragana: 'ら', romaji: 'ra', english: 'Ra in transliteration of Pāramitā'},
        {key: '186', kanji: '蜜', hiragana: 'みっ', romaji: 'mit', english: 'Mit in transliteration of Pāramitā'},
        {key: '187', kanji: '多', hiragana: 'た', romaji: 'ta', english: 'Ta in transliteration of Pāramitā'},
        {key: '188', kanji: '故', hiragana: 'こ', romaji: 'ko', english: 'late, deceased'},
        {key: '189', kanji: '得', hiragana: 'とく', romaji: 'toku', english: 'benefit'},
        {key: '190', kanji: '呵', hiragana: 'あ', romaji: 'a', english: 'reprove?'},
        {key: '191', kanji: '耨', hiragana: 'んおく', romaji: 'noku', english: ''},
        {key: '192', kanji: '多', hiragana: 'た', romaji: 'ta', english: 'Many, Much'},
        {key: '193', kanji: '羅', hiragana: 'ら', romaji: 'ra', english: '?'},
        {key: '194', kanji: '三', hiragana: 'さん', romaji: 'sam', english: 'three'},
        {key: '195', kanji: '藐', hiragana: 'みゃく', romaji: 'myaku', english: 'to ignore, to slight, to disregard, to disdain'},
        {key: '196', kanji: '三', hiragana: 'さん', romaji: 'sam', english: 'three'},
        {key: '197', kanji: '菩', hiragana: 'ぼ', romaji: 'bo', english: '?'},
        {key: '198', kanji: '提', hiragana: 'だい', romaji: 'dai', english: 'to lower'},
        {key: '199', kanji: '故', hiragana: 'こ', romaji: 'ko', english: 'late, deceased'},
        {key: '200', kanji: '知', hiragana: 'ち', romaji: 'chi', english: 'wisdom, to know'},
        {key: '201', kanji: '般', hiragana: 'はん', romaji: 'han', english: 'Han in transliteration of Prajñā'},
        {key: '202', kanji: '若', hiragana: 'にゃ', romaji: 'nya', english: 'Nya in transliteration of Prajñā'},
        {key: '203', kanji: '波', hiragana: 'は', romaji: 'ha', english: 'Ha in transliteration of Pāramitā'},
        {key: '204', kanji: '羅', hiragana: 'ら', romaji: 'ra', english: 'Ra in transliteration of Pāramitā'},
        {key: '205', kanji: '蜜', hiragana: 'みっ', romaji: 'mit', english: 'Mit in transliteration of Pāramitā'},
        {key: '206', kanji: '多', hiragana: 'た', romaji: 'ta', english: 'Ta in transliteration of Pāramitā'},
        {key: '207', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '208', kanji: '大', hiragana: 'だい', romaji: 'dai', english: 'great, big, large'},
        {key: '209', kanji: '神', hiragana: 'じん', romaji: 'jin', english: 'spirit, diety'},
        {key: '210', kanji: '呪', hiragana: 'す', romaji: 'shu', english: 'mantra'},
        {key: '211', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '212', kanji: '大', hiragana: 'だい', romaji: 'dai', english: 'great, big, large'},
        {key: '213', kanji: '明', hiragana: 'みょう', romaji: 'myō', english: 'clear, bright'},
        {key: '214', kanji: '呪', hiragana: 'す', romaji: 'shu', english: 'mantra'},
        {key: '215', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '216', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '217', kanji: '上', hiragana: 'じょう', romaji: 'jo', english: 'above'},
        {key: '228', kanji: '呪', hiragana: 'す', romaji: 'shu', english: 'mantra'},
        {key: '229', kanji: '是', hiragana: 'ぜ', romaji: 'ze', english: 'right, correct'},
        {key: '220', kanji: '無', hiragana: 'む', romaji: 'mu', english: 'No'},
        {key: '221', kanji: '等', hiragana: 'とう', romaji: 'to', english: 'rank, grade,(chinese) equal'},
        {key: '222', kanji: '等', hiragana: 'どう', romaji: 'do', english: 'rank, grade,(chinese) equal'},
        {key: '223', kanji: '呪', hiragana: 'す', romaji: 'shu', english: 'mantra'},
        {key: '224', kanji: '能', hiragana: 'のう', romaji: 'nō', english: 'ability, skill, talent, capacity'},
        {key: '225', kanji: '除', hiragana: 'じょ', romaji: 'jo', english: 'to exclude, to remove'},
        {key: '226', kanji: '一', hiragana: 'いっ', romaji: 'is', english: 'one'},
        {key: '227', kanji: '切', hiragana: 'さい', romaji: 'sai', english: 'cut'},
        {key: '238', kanji: '苦', hiragana: 'く', romaji: 'ku', english: 'dukkha'},
        {key: '239', kanji: '真', hiragana: 'しん', romaji: 'shin', english: 'true, reality, Buddhist sect'},
        {key: '230', kanji: '実', hiragana: 'じつ', romaji: 'jitsu', english: 'truth, reality'},
        {key: '231', kanji: '不', hiragana: 'ふ', romaji: 'fu', english: 'prefix (non)'},
        {key: '232', kanji: '虚', hiragana: 'こ', romaji: 'ko', english: 'false'},
        {key: '233', kanji: '故', hiragana: 'こ', romaji: 'ko', english: 'late, deceased'},
        {key: '234', kanji: '説', hiragana: 'せつ', romaji: 'setsu', english: 'theory, explanation'},
        {key: '235', kanji: '般', hiragana: 'はん', romaji: 'han', english: 'Han in transliteration of Prajñā'},
        {key: '236', kanji: '若', hiragana: 'にゃ', romaji: 'nya', english: 'Nya in transliteration of Prajñā'},
        {key: '237', kanji: '波', hiragana: 'は', romaji: 'ha', english: 'Ha in transliteration of Pāramitā'},
        {key: '248', kanji: '羅', hiragana: 'ら', romaji: 'ra', english: 'Ra in transliteration of Pāramitā'},
        {key: '249', kanji: '蜜', hiragana: 'みっ', romaji: 'mit', english: 'Mit in transliteration of Pāramitā'},
        {key: '240', kanji: '多', hiragana: 'た', romaji: 'ta', english: 'Ta in transliteration of Pāramitā'},
        {key: '241', kanji: '呪', hiragana: 'す', romaji: 'shu', english: 'mantra'},
        {key: '242', kanji: '即', hiragana: 'そく', romaji: 'soku', english: 'promptly, quickly, immediately'},
        {key: '243', kanji: '説', hiragana: 'せつ', romaji: 'setsu', english: 'theory, explanation'},
        {key: '244', kanji: '呪', hiragana: 'す', romaji: 'shu', english: 'mantra'},
        {key: '245', kanji: '日', hiragana: 'わつ', romaji: 'watsu', english: 'sun, day, japan?'},
        {key: '246', kanji: '羯', hiragana: 'ぎゃ', romaji: 'gya', english: 'ga transliteration for Gate'},
        {key: '247', kanji: '諦', hiragana: 'てい', romaji: 'te', english: 'te transliteration for Gate'},
        {key: '258', kanji: '羯', hiragana: 'ぎゃ', romaji: 'gya', english: 'ga transliteration for Gate'},
        {key: '259', kanji: '諦', hiragana: 'てい', romaji: 'te', english: 'te transliteration for Gate'},
        {key: '250', kanji: '波', hiragana: 'は', romaji: 'ha', english: 'ha transliteration for Paragate'},
        {key: '251', kanji: '羅', hiragana: 'ら', romaji: 'ra', english: 'ra transliteration for Paragate'},
        {key: '252', kanji: '羯', hiragana: 'ぎゃ', romaji: 'gya', english: 'ga transliteration for Paragate'},
        {key: '253', kanji: '諦', hiragana: 'てい', romaji: 'te', english: 'te transliteration for Paragate'},
        {key: '254', kanji: '波', hiragana: 'は', romaji: 'ha', english: 'ha transliteration for Parasamgate'},
        {key: '255', kanji: '羅', hiragana: 'ら', romaji: 'ra', english: 'ra transliteration for Parasamgate'},
        {key: '256', kanji: '僧', hiragana: 'そ', romaji: 'so', english: 'so transliteration for Parasamgate also, buddhist priest'},
        {key: '257', kanji: '羯', hiragana: 'ぎゃ', romaji: 'gya', english: 'ga transliteration for Parasamgate'},
        {key: '268', kanji: '諦', hiragana: 'てい', romaji: 'te', english: 'te transliteration for Parasamgate'},
        {key: '269', kanji: '菩', hiragana: 'ぼ', romaji: 'bo', english: 'bodhisattva'},
        {key: '260', kanji: '提', hiragana: 'じ', romaji: 'ji', english: 'to lower?'},
        {key: '261', kanji: '薩', hiragana: 'そ', romaji: 'so', english: 'sa transliteration for svaha'},
        {key: '262', kanji: '婆', hiragana: 'わ', romaji: 'wa', english: 'wa transliteration for svaha'},
        {key: '263', kanji: '訶', hiragana: 'か', romaji: 'ka', english: 'ha transliteration for svaha'},
        {key: '264', kanji: '般', hiragana: 'はん', romaji: 'han', english: 'Han in transliteration of Prajñā'},
        {key: '265', kanji: '若', hiragana: 'にゃ', romaji: 'nya', english: 'Nya in transliteration of Prajñā'},
        {key: '266', kanji: '心', hiragana: 'しん', romaji: 'shin', english: 'heart'},
        {key: '267', kanji: '經', hiragana: 'ぎょ', romaji: 'gyo', english: 'sutra'},
    ],
    title: [
        {key: '001', kanji: '摩', hiragana: 'ま', romaji: 'Ma', english: 'Great'},
        {key: '002', kanji: '訶', hiragana: 'か', romaji: 'Ka', english: 'Great'},
        {key: '003', kanji: '般', hiragana: 'はん', romaji: 'Han', english: 'Wisdom (transliteration of Prajñā)'},
        {key: '004', kanji: '若', hiragana: 'にゃ', romaji: 'Nya', english: 'Wisdom (transliteration of Prajñā)'},
        {key: '005', kanji: '波', hiragana: 'は', romaji: 'Ha', english: 'Perfect (transliteration of Pāramitā)'},
        {key: '006', kanji: '羅', hiragana: 'ら', romaji: 'Ra', english: 'Perfect (transliteration of Pāramitā)'},
        {key: '007', kanji: '蜜', hiragana: 'みっ', romaji: 'Mit', english: 'Perfect (transliteration of Pāramitā)'},
        {key: '008', kanji: '多', hiragana: 'た', romaji: 'Ta', english: 'Perfect (transliteration of Pāramitā)'},
        {key: '009', kanji: '心', hiragana: 'しん', romaji: 'Shin', english: 'Heart'},
        {key: '010', kanji: '経', hiragana: 'ぎょ', romaji: 'Gyo', english: 'Sutra'}
    ]
};

module.exports = heartsutra;