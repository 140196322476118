import { useSelector } from "react-redux";
const React = require("react");
const cx = require("classnames");
const {kanjiDict} = require("../sutras/kanjiDict")
export function Kanjiblob(props) {
  const displayKanji = useSelector((state) => state.displayKanji);
  const displayStrokeOrder = useSelector((state) => state.displayStrokeOrder);
  const displayHiragana = useSelector((state) => state.displayHiragana);
  const displayRomaji = useSelector((state) => state.displayRomaji);
  const displayEnglish = useSelector((state) => state.displayEnglish);
  const displayCharacterNum = useSelector((state) => state.displayCharacterNum);

  const clickKanji = function (evnt) {
    let url;
    if (evnt.altKey) {
      url = "http://jisho.org/search/" + evnt.target.innerHTML + " #kanji";
    } else {
      url =
        "https://en.wiktionary.org/wiki/" + evnt.target.innerHTML + "#Japanese";
    }
    window.open(url);
  };

  const { kanji, hiragana, romaji, english, key } = props.kanjiBlob;
  const classNames = cx("blob", displayStrokeOrder ? 'stroke-order': 'kanji');
  return (
    <div className={classNames}>
      <div className={cx("kanji", !displayKanji ? "hidden" : "")}>
        <a href="#" onClick={clickKanji} target="new">
          {displayStrokeOrder ? 
          <span dangerouslySetInnerHTML={{__html: kanjiDict[kanji]}} />
           : kanji}
        </a>
      </div>
      <div
        className={cx(
          "meta",
          !displayCharacterNum &&
            !displayCharacterNum &&
            !displayRomaji &&
            !displayEnglish &&
            !displayHiragana
            ? "hidden"
            : ""
        )}
      >
        <div
          className={cx(
            "characterNum",
            displayCharacterNum !== true ? "hidden" : ""
          )}
        >
          {key}
        </div>
        <div className={cx("romaji", !displayRomaji ? "hidden" : "")}>
          {romaji}
        </div>
        <div className={cx("english", !displayEnglish ? "hidden" : "")}>
          {english}
        </div>
        <div className={cx("hiragana", !displayHiragana ? "hidden" : "")}>
          {hiragana}
        </div>
      </div>
    </div>
  );
}
